<template>
  <div>
    <h1 class="p-5">{{ set.name }}</h1>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'ProductsSetBlockRender',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      set: {
        name: null
      }
    }
  },
  methods: {
    async fetchSet () {
      await this.$api.get('products-set', {
        params: {
          set: this.item.content.set
        }
      })
        .then((response) => {
          this.set = response.data.set
        }).catch((error) => {
          console.log(error)
          Vue.$toast.error('Something went wrong while fetching products sets. Please refresh the page and try again!')
        })
    }
  },
  mounted () {
    this.fetchSet()
  }
}
</script>

<style scoped>

</style>
